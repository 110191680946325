import type { SiteConfig } from '@/_types'

export const siteConfig: SiteConfig = {
  name: 'receipts',
  host: 'preview.receipts-app.com',

  languages: ['en', 'de'],
  i18nLocalesModules: {
    en: () => import('../locales/en.json'),
    de: () => import('../locales/de.json'),
  },

  siteTitle: 'Receipts Mac App',
  baseUrl: 'https://preview.receipts-app.com',
  iconUrl: 'https://preview.receipts-app.com/icon-1024.png',

  posts: () => import('./_posts/meta.json'),
  postsPath: 'blog',

  productMinOSX: '12.4',

  // plausibleId: 'receipts-app.com',
  // plausibleAppId: 'app.receipts-app.com',

  plausibleId: 'receipts-app.com',
  plausibleAppId: 'app.receipts-app.com',

  repliesProductName: 'Receipts2',

  // Activate social media sharing
  shareIntro: 'Found on "Receipts - Document management for finances that learns!"',

  supportEmail: 'support@receipts-app.com',

  productReleases: () => import('./_macos/releases.json'),
}
